<template>
  <div>
    <PageHeader />
    <b-card>
      <b-row>
        <b-col>
          <h6>
            {{ `${$t('fields.result')} (${mockRecords.length})`}}
          </h6>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        :items="mockRecords"
        :fields="fields"
        :busy="credit.isFetchingMasterAgentCredits"
        responsive
      >
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(description)="data">
          <b-badge
            v-if="data.value === 'deposit' || data.value === 'direct-deposit' "
            class="badge-soft-success"
          >
            {{ data.value === 'direct-deposit'? $t('credits.direct_deposit'): $t('credits.deposit')}}
          </b-badge>
          <b-badge
            v-if="data.value === 'withdraw' || data.value === 'direct-withdraw' "
            class="badge-soft-danger"
          >
            {{ data.value === 'direct-withdraw'? $t('credits.direct_withdraw'): $t('credits.withdraw')}}
          </b-badge>
        </template>
        <template #cell(credits)="data">
          <span
            v-if="data.item.description === 'deposit'"
            class="text-success"
          >
            {{ data.value | currency }}
          </span>
          <span
            v-else
            class="text-danger"
          >
            - {{ data.value | currency }}
          </span>
        </template>
        <template #cell(remainCredits)="data">
          {{ data.value | currency }}
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal
      v-model="isCreditTransferModalActive"
      :title="`${$t('routes.credits_transfer')} ( ${$t('agent.title')}-${selectedAgent.name|| ''})`"
      hide-footer
    >
      <CreditTransferForm @close="onCloseModal" />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  page: {
    title: 'Player Credit Transaction',
  },
  components: {
    CreditTransferForm: () =>
      import('../../../components/forms/credit-transfer-form'),
  },
  data() {
    return {
      isCreditTransferModalActive: false,
      selectedAgent: {},
      fields: [
        '#',
        { key: 'transactionNo', label: `${this.$t('credits.transaction_no')}` },
        {
          key: 'description',
          label: this.$t('fields.description'),
        },
        { key: 'operatedBy', label: this.$t('credits.operated_by') },
        {
          key: 'receiver',
          label: this.$t('credits.receiver'),
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'credits',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'remainCredits',
          label: this.$t('credits.remain_credits'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'issuedDate',
          label: this.$t('fields.issued_date'),
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'remark',
          label: `${this.$t('fields.remark')}`,
          thStyle: {
            minWidth: '150px',
          },
        },
      ],
      mockRecords: [
        {
          transactionNo: '1390',
          description: 'deposit',
          operatedBy: 'Master',
          receiver: 'Umore123',
          credits: 1000000,
          remainCredits: 300000,
          issuedDate: '10/10/2021',
          remark: '',
        },
        {
          transactionNo: '1390',
          description: 'withdraw',
          operatedBy: 'Master',
          receiver: 'Umore777',
          credits: 90000,
          remainCredits: 238990000,
          issuedDate: '10/10/2021',
          remark: '',
        },
      ],
    }
  },
  computed: {
    ...mapState(['credit']),
    ...mapGetters(['']),
  },

  created() {
    // this.fetchMasterAgentCredits(id)
  },
  methods: {
    ...mapActions(['fetchMasterAgentCredits']),
    toggleModal(agent) {
      this.isCreditTransferModalActive = !this.isCreditTransferModalActive
      this.selectedAgent = agent
    },
    onCloseModal() {
      this.isCreditTransferModalActive = false
      this.selectedAgent = {}
    },
  },
}
</script>